import { graphql } from "gatsby"
import _ from "lodash"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { PageLinks } from "../common/site/page-static-links"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { formTracking } from "../components/Common/utils"
import NewsDetailsBanner from "../components/NewsDetailsBanner/NewsDetailsBanner"
import NewsImageBlock from "../components/NewsImageBlock/NewsImageBlock"
import NewsSlider from "../components/NewsSlider/NewsSlider"
import NewsStaticComponent from "../components/NewsStaticComponent/NewsStaticComponent"
import NewsTextBlock from "../components/NewsTextBlock/NewsTextBlock"
import PodCastListing from "../components/PodCastListing/PodCastListing"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NewsDetails = ({ data }) => {
  const blogData = data?.strapiBlog
  const blogArr = data?.allStrapiBlog?.edges

  const [shareIcons, setShareIcons] = useState(false)

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const category =
    blogData?.blog_categories?.length > 0 && blogData?.blog_categories[0].slug

  const filtered = _.filter(blogArr, function (o) {
    if (o.node.blog_categories?.length > 0) {
      return o.node.blog_categories?.some(item => item.slug === category)
    }
  })

  const splitUrl = pageurl.split("/")
  const currentNews = splitUrl[splitUrl.length - 2]

  const sliderNews = filtered?.filter(item => {
    return item.node?.slug !== currentNews
  })

  const PageData =
    data?.allStrapiPage?.edges?.length > 0
      ? data?.allStrapiPage?.edges[0]?.node
      : ""

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  var ldJson = {
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": pageurl,
    },
    headline: blogData?.title,
    image: [blogData?.banner_image?.url],
    datePublished: blogData?.publishedAt,
    dateModified: blogData?.updatedAt,
    author: {
      "@type": "Person",
      name: blogData?.published_by?.name,
    },
    publisher: {
      "@type": "Organization",
      name: "hand & haus",
      logo: {
        "@type": "ImageObject",
        url: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/logo_white_schema_9fd27d4e15.png",
      },
    },
    description: `Read about ${blogData?.title} here and subscribe to our newsletter to stay up-to-date about everything going on at haus & haus.`,
  }

  return (
    <Layout popUpData={popUpData} nonpropertyDetails dark={true}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(ldJson, null, 2)}
        </script>
      </Helmet>
      <div className="layout-padding-top"></div>
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            mainparentname={`Resources`}
            mainparent={PageLinks.resources}
            parentname={`Latest News and Insights`}
            type={"details-page"}
            parent={PageLinks.news}
            pagename={blogData?.title}
          />
          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>
        </div>
      </Container>
      {blogData && <NewsDetailsBanner blogData={blogData} />}
      {blogData?.blog_content?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS" && (
              <PodCastListing podcastsList={item} />
            )}
            {item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TEXT_BLOCK" && (
              <NewsTextBlock newsContent={item} />
            )}
            {item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_IMAGE" && (
              <NewsImageBlock imageData={item} blogData={blogData} />
            )}
          </div>
        )
      })}

      {blogData && <NewsStaticComponent blogData={blogData} />}
      {sliderNews?.length > 0 && (
        <NewsSlider
          className="news-details-slider"
          data={sliderNews?.slice(0, 4)}
          title="You might also be interested in..."
        />
      )}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const blogData = data.strapiBlog

  let myimgtransforms = ""
  if (blogData?.imagetransforms?.tile_image_Transforms) {
    myimgtransforms = JSON.parse(
      blogData?.imagetransforms?.tile_image_Transforms
    )
  }
  let pageImg = ""
  if (Object.keys(myimgtransforms)?.length > 0) {
    let pageImg_filt = []
    for (const myimgtransform in myimgtransforms) {
      if (typeof myimgtransforms[myimgtransform]["webp"] !== "undefined") {
        pageImg_filt = Object.values(myimgtransforms[myimgtransform]["webp"])
        break
      }
    }
    if (pageImg_filt.length > 0) pageImg = pageImg_filt.length>0?pageImg_filt[0]:blogData?.banner_image?.url
  }
  const imageUrl=pageImg


  const metaTitle = blogData?.title
  const metaDesc = `Read about ${blogData?.title} here and subscribe to our newsletter to stay up-to-date about everything going on at haus & haus.`

  return <SEO title={metaTitle} description={metaDesc} imageUrl={imageUrl}></SEO>
}

export default NewsDetails

export const query = graphql`
  query ($page_id: Int) {
    strapiBlog(strapi_id: { eq: $page_id }) {
      banner_image {
        url
        caption
      }
      content {
        data {
          content
        }
      }

      blog_categories {
        blog_category
        slug
      }

      imagetransforms {
        tile_image_Transforms
        banner_image_Transforms
      }

      publishedAt
      updatedAt
      id
      read_time
      date
      slug
      strapi_id
      tile_image {
        url
      }
      title

      video_url
      published_by {
        image {
          url
        }
        name
        phone
        designation
        email
        slug
      }
      card_list {
        items {
          cta_title
          content {
            data {
              content
            }
          }
          custom_link
          image {
            alternativeText
            url
          }
          link_type
        }
      }
      news_detail_member {
        content {
          data {
            content
          }
        }
        designation
        image {
          url
        }
        name
      }
      blog_content {
        ... on STRAPI__COMPONENT_COMPONENTS_TEXT_BLOCK {
          id
          __typename
          content {
            data {
              content
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS {
          id
          __typename
          heading
          items {
            cta_title
            custom_link
            link_type
            image {
              alternativeText
              url
            }
            content {
              data {
                content
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE {
          id
          __typename
          image {
            url
          }
        }
      }
    }

    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          strapi_id
          read_time
          date
          tile_image {
            url
          }
          imagetransforms {
            tile_image_Transforms
          }

          blog_categories {
            blog_category
            slug
          }
        }
      }
    }

    allStrapiPage(filter: { layout: { eq: "news_landing_page" } }) {
      edges {
        node {
          title
          add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
              __typename
              ...GlobalPopUpFragment
            }
          }
        }
      }
    }
  }
`
