import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "./NewsDetailsBanner.scss"
import PlayVideo from "../PlayVideo/PlayVideo"
import dateFormat from "dateformat"
import { Link } from "gatsby"
import { teamURL } from "../../lib/urls"
import YoutubePlayer from "../YoutubePlayer/YoutubePlayer"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const NewsDetailsBanner = ({ blogData }) => {
  const [isPlay, setPlay] = useState(false)
  const {
    banner_image,
    category,
    blog_categories,
    content,
    published_by,
    imagetransforms,
    read_time,
    strapi_id,
    date,
    tile_image,
    title,
    topics,
    video_url,
  } = blogData

  let processedImages
  processedImages =
    imagetransforms?.banner_image_Transforms || JSON.stringify({})

  const imagename = "blog.banner_image.details"

  const podCast = blog_categories?.some(item => item.slug === "podcasts")
  const imageCaption = banner_image?.caption;

  return (
    <div className="news-details-banner">
      <Container>
        <div className="new-details-banner-sections">
          <h1 className="news-heading">{title}</h1>
          {/* {podCast && (
            <p className="description podcast">
              <ContentModule Content={content?.data?.content} />
            </p>
          )} */}

          {!podCast && published_by && (
            <div className="team-member">
              <div className="image-section">
                <img src={published_by?.image.url} alt={published_by?.name + " "+" - haus & haus"}/>
              </div>
              <div className="team-info">
                <div className="name-designation">
                  <Link to={`${teamURL}${published_by?.slug}/`}>
                    <p className="name">{published_by?.name}</p>
                  </Link>
                  <p className="designation">{published_by?.designation}</p>
                </div>
                <p className="date-time">
                  <span>{read_time}</span>{" "}
                  <span className="date">
                    {dateFormat(date, "mmm dd, yyyy")}
                  </span>
                </p>
              </div>
            </div>
          )}
          <div className="detail-banner-container">
            <div className="image-section">
              {/* <ImageModule
                ImageSrc={banner_image}
                altText={`${title}`}
                imagetransforms={processedImages}
                renderer="pic-src"
                imagename={imagename}
                strapi_id={strapi_id}
                classNames="img-fluid"
              /> */}
              {!video_url?
              <figure>
                <img src={banner_image?.url} alt={`${title}`} classNames="img-fluid"/>
                {!video_url && imageCaption &&
                <figcaption>{imageCaption}</figcaption>
                }
              </figure>
              :
              <YoutubePlayer video_url={video_url}/>
            }
            {/* {!video_url && imageCaption &&
              <p className="caption-text">{imageCaption}</p>
            } */}
            </div>
              <p className="description">
                <ContentModule Content={content?.data?.content} />
              </p>
          </div>
        </div>
      </Container>
      <PlayVideo
        isOpen={isPlay}
        stopPlay={setPlay}
        videoId=""
        isCloseFunction={setPlay}
        videourl={video_url}
        htmlink={""}
      />
    </div>
  )
}

export default NewsDetailsBanner
