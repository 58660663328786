import React from 'react'
import { Container } from "react-bootstrap"
import "./NewsTextBlock.scss"

const {
    ContentModule,
  } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const NewsTextBlock = ({newsContent}) => {
  return (
    <div className="news-content-block">
        <Container>
            <div className="content-section-news">
            <p className="description">
              <ContentModule Content={newsContent?.content?.data?.content} />
            </p>
            </div>
        </Container>
    </div>
  )
}

export default NewsTextBlock